@media print {
    html, body {
        height: 100vh;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }
    .nhf-header {
        margin-left: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .nhf-header img{
        float: left;
        height: 100px;
    }

    .header {
        clear:both;
        border-bottom: 1px solid #163e6a;
        margin-top: 70px;
        margin-bottom: 0px;
        height: 50px;
    }

    .test-details,
    .patient-details {
        clear:both;
        margin-top: 20px;
    }

    .title {
        padding: 5px 0px;
        margin-top: 20px;
    }
    table {
        border-collapse: collapse;
        width: 100%;
        border: 1px solid #ccc;
    }

    th,
    td {
        padding: 8px;
        text-align: left;
        border: 1px solid #ccc;
    }

    th {
        font-weight: bold;
    }
}