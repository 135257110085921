.srv-validation-message {
  color: red;
  font-size: 0.875rem;
}

.remarks-wrapper {
  display: none;
}

.remarks-table {
  padding: 0;
  margin: 0 0 25px 0;
  width: 100%;
}

.remarks-table thead tr td {
  font-size: 14px;
  color: #0b427e;
  padding: 10px;
  border-bottom: solid 1px #a0a0a0;
}

.remarks-table tbody tr td {
  font-size: 12px;
  padding: 10px;
  border-bottom: solid 1px #cdd1d6;
}

/* Absolute Center Spinner */

.loading {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */

input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */

input[type="date"]::-webkit-clear-button {
  z-index: 1;
}

input[type="date"] {
  position: relative;
}

.input-tooltip input {
  display: inline-block;
  width: 92%;
}

.removesearch {
  margin-left: 5px;
  background: white;
  color: #0b427e;
  border-radius: 50%;
  display: inline-flex;
  width: 17px;
  justify-content: center;
  align-items: center;
  padding: 1px 0;
  box-sizing: border-box;
}

.suggesstion {
  position: relative;
}

.suggesstion-list {
  list-style-type: none;
  padding: 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  width: 100%;
  position: absolute;
  top: 100%;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.suggesstion-list:empty {
  border-radius: 0;
  border: 0;
}

.suggesstion-list li {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  list-style-type: none;
  padding: 10px;
  font-size: 13px;
  color: #16181b;
}

.suggesstion-list li.load-list {
  color: #666;
  opacity: 0.8;
}

.suggesstion-list li.allow-new:not(.active) {
  background-color: #efefef;
}

.suggesstion-list li:not(.load-list):hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d518a;
}

.suggesstion-list li.active {
  color: #fff;
  text-decoration: none;
  background-color: #1d518a;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

select[disabled] {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}

.sign_prescription a {
  background: #fff;
  display: inline-block;
  padding: 5px;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px #cbd3dc;
  text-decoration: underline;
}

.pending_signature input[type="checkbox"] {
  position: unset;
  opacity: 1;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #0b417d;
}

.btn-primary:hover {
  color: #fff !important;
}

.btn-none {
  padding: 0;
  border: none;
  background: none;
}

.btn-none:focus {
  outline: none;
}

/* react-bootstrap-table2 */
.react-bs-table-sizePerPage-dropdown #pageDropDown {
  margin: 0;
  padding: 0.5rem 0.5rem;
  font-size: 12px;
  border: 1px solid #dee2e6;
}

th.sortable {
  cursor: pointer;
}

th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

th .order-4:after {
  content: "\2193";
  opacity: 0.4;
}

.pl-30 {
  padding-left: 30px !important;
}

.labReport-box {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.referred-label {
  margin: 0;
  font-size: large;
}

.referred-checkBox {
  margin-left: 15px;
  border-color: #E4EFFF;
}